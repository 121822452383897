<template>
  <CContainer fluid class="mainTableContainer">
    <CRow alignVertical="center" alignHorizontal="center">
      <CCol lg="12" class="mainTableColumn">
        <CButton color="primary" @click="openCreate">Create</CButton>
      </CCol>
      <CCol lg="12" class="mainTableColumn">
        <CDataTable
          :noItemsView="{
            noResults: 'no items found',
            noItems: 'no items',
          }"
          :hover="true"
          :striped="true"
          :items-per-page="20"
          columnFilter
          sorter
          pagination
          :items="liteLabelsList"
          :clickableRows="true"
          :loading="loading"
          :fields="fields"
          size="sm"
        >
          <template #options="{ item }">
            <td>
              <Pencil :size="20" @click="editOpen(item)"></Pencil>
              <Delete :size="20" @click="deleteOpen(item)"></Delete>
            </td>
          </template>
        </CDataTable>
      </CCol>
    </CRow>
    <DeleteConfirmModal
      :show="showDelete"
      @close="hideDelete"
      @accept="deleteItemConfirm"
    />
    <LiteLabelModal
      v-if="showModal"
      :show-modal="showModal"
      :eventType="eventType"
      :label="label"
      @close="closeModal"
      @accept="confirmModal"
    />
  </CContainer>
</template>
<script lang="js">
import {liteLabelModule} from "@/store/modules/liteLabelModule";
import {CContainer,CRow,CCol,CDataTable,CButton} from "@coreui/vue/src";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Delete from "vue-material-design-icons/Delete.vue";

export default {
  name:"LiteLabelTemplate",
  components:{
    CContainer,CRow,CCol,CDataTable,CButton, Pencil, Delete,
    DeleteConfirmModal: () => import("@/components/modals/DeleteConfirmModal.vue"),
    LiteLabelModal: () => import("@/components/modals/LiteLabelModal.vue")
  },
  data(){
    return{
      eventType:'create',
      showModal:false,
      label:null,
      deleteItem:null,
      showDelete:false,
      fields: [
        { label: "ID", key: "id", sorter: true, filter: true },
        { label: "Name", key: "name", sorter: true, filter: true },
        { label: "Header text", key: "header_text", sorter: true, filter: true },
        {
          label: "Bottom text",
          key: "bottom_text",
          sorter: true,
          filter: true,
        },
        { label: "Options", key: "options", sorter: false, filter: false },
      ],
      defaultLabel:{
        name:"",
        header_text:"",
        qr:"",
        bottom_text:""
      }
    }
  },
  computed:{
    ...liteLabelModule.mapState(['loading']),
    ...liteLabelModule.mapGetters(['liteLabelsList']),
  },
  methods:{
    ...liteLabelModule.mapActions(['getLiteLabels',"deleteLiteLabel","postLiteLabel","putLiteLabel"]),
    async confirmModal(){
      if(this.eventType === 'create'){
        await this.postLiteLabel(this.label);
      }
      if(this.eventType === 'edit'){
        await this.putLiteLabel(this.label);
      }
      this.showModal = false;
    },
    closeModal(){
      this.showModal = false;
    },
    openCreate(){
      this.label = JSON.parse(JSON.stringify(this.defaultLabel));
      this.showModal = true;
    },
    hideDelete(){
      this.showDelete = false;
    },
    async deleteItemConfirm(){
      await this.deleteLiteLabel(this.deleteItem.id);
      this.showDelete = false;
    },
    editOpen(item){
      this.eventType = 'edit';
      this.label = JSON.parse(JSON.stringify(item));
      this.showModal = true;
    },
    deleteOpen(item){
      this.deleteItem = JSON.parse(JSON.stringify(item));
      this.showDelete = true;
    }
  },
  async created(){
    await this.getLiteLabels();
  }
}
</script>

<style scoped lang="scss"></style>
